<template>
    <div class="activity-participation">
        <!-- 搜索区域 -->
        <div class="search-wrapper">
            <el-form :inline="true" :model="searchForm" class="search-form" size="mini">
                <el-form-item label="课程名称">
                    <el-input v-model="searchForm.name" placeholder="请输入课程名称"></el-input>
                </el-form-item>
                <el-form-item label="课程分类">
                    <el-select v-model="searchForm.category" placeholder="课程分类" clearable>
                        <el-option label="软件工程" value="软件工程"></el-option>
                        <el-option label="社会科学" value="社会科学"></el-option>
                        <el-option label="人文历史" value="人文历史"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="授课教师">
                    <el-input v-model="searchForm.name" placeholder="请输入授课教师"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">搜索</el-button>
                    <el-button @click="resetSearch">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 活动卡片区域 -->
        <div class="activity-list">
            <el-row :gutter="20">
                <el-col :span="6" v-for="activity in activityList" :key="activity.id">
                    <el-card class="activity-card" :body-style="{ padding: '0px' }">
                        <div class="activity-image">
                            <el-image :src="activity.image" fit="cover">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <div class="activity-info">
                            <h3 class="activity-name">{{ activity.name }}</h3>
                            <div class="activity-category">
                                <el-tag type="primary" size="mini">{{ activity.category }}</el-tag>

                                <el-tag type="success" size="mini">{{ activity.teacher }}</el-tag>
                            </div>
                            <div class="activity-detail">
                                <span>地点：{{ activity.location }}</span>
                                <span>时长：{{ activity.duration }}小时</span>
                            </div>
                            <div class="activity-time">
                                <i class="el-icon-time"></i>
                                {{ activity.startTime }} 至 {{ activity.endTime }}
                            </div>
                            <div class="activity-participants">
                                <i class="el-icon-user"></i>
                                浏览次数：{{ activity.participants }}
                            </div>
                            <div class="activity-actions" v-if="userMsg.role!='admin'">
                                <!-- <template v-if="!isProcessing(activity)">
                                    <el-button
                                        :type="getParticipationBtnType(activity)"
                                        size="mini"
                                        :loading="activity.loading"
                                        @click="handleParticipation(activity)">
                                        {{ getParticipationBtnText(activity) }}
                                    </el-button>
                                </template> -->
                                <!-- <template v-else>
                                    <el-tag size="small" type="info">{{ getProcessingText(activity) }}</el-tag>
                                </template> -->
                                <el-button
                                    type="text"
                                    size="mini"
                                    @click="showActivityDetail(activity)">
                                    查看详情
                                </el-button>
                            </div>
                            <div v-else>
                                <el-button
                                    type="text"
                                    size="mini"
                                    @click="showActivityDetail(activity)">
                                    查看详情
                                </el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 分页 -->
        <div class="pagination-wrapper">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :page-sizes="[8, 16, 24, 32]"
                :page-size="pagination.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total">
            </el-pagination>
        </div>

        <!-- 课程详情弹窗 -->
        <el-dialog title="课程详情" :visible.sync="dialogVisible" width="80%">
            <div class="detail-content">
                <div class="detail-item">
                    <span class="detail-label">课程名称：</span>
                    <span class="detail-value">{{ currentActivity.name }}</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">课程类型：</span>
                    <span class="detail-value">{{ currentActivity.category }}</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">授课教师：</span>
                    <span class="detail-value">{{ currentActivity.teacher }}</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">授课地点：</span>
                    <span class="detail-value">{{ currentActivity.location }}</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">课程时长：</span>
                    <span class="detail-value">{{ currentActivity.duration }} 小时</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">课程时间：</span>
                    <span class="detail-value">{{ currentActivity.startTime }} 至 {{ currentActivity.endTime }}</span>
                </div>
                <div class="detail-item">
                    <span class="detail-label">课程详情：</span>
                    <span class="detail-value">{{ currentActivity.description }}</span>
                </div>
                <div class="detail-item teaching-content">
                    <span class="detail-label">教学内容：</span>
                    <div class="content-box">
                        {{ currentActivity.teachingContent }}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import request from '@/utils/request';
export default {
    data() {
        return {
            searchForm: {
                name: '',
                category: '',
                location: ''
            },
            activityList: [],
            pagination: {
                currentPage: 1,
                pageSize: 8,
                total: 0
            },
            dialogVisible: false,
            currentActivity: {},
            participationStatus: {},
            userMsg:null
        }
    },
    mounted() {
        this.userMsg=JSON.parse(localStorage.getItem('userInfo'))
        this.getActivityList()
    },
    methods: {
        async getActivityList() {
            try {
                const res = await request.get('/api/volunteer-activity', {
                    params: {
                        ...this.searchForm,
                        page: this.pagination.currentPage,
                        pageSize: this.pagination.pageSize,
                        userId:this.userMsg.userId
                    }
                });
                
                if (res.data.code === 200) {
                    this.activityList = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                    
                    const statusRes = await request.get('/api/volunteer-activity/status', {
                        params: {
                            activityIds: this.activityList.map(activity => activity.id).join(',')
                        }
                    });
                    
                    if (statusRes.data.code === 200) {
                        this.participationStatus = statusRes.data.data;
                    }
                }
            } catch (error) {
                this.$message.error('获取活动列表失败');
            }
        },

        // 判断是否处于审核中（参与或退出审核中）
        isProcessing(activity) {
            const status = this.participationStatus[activity.id];
            return status === '参与待审核' || status === '退出待审核';
        },

        // 获取审核中的文本提示
        getProcessingText(activity) {
            const status = this.participationStatus[activity.id];
            return status === '参与待审核' ? '参与审核中' : '退出审核中';
        },

        // 获取参与按钮的按钮类型
        getParticipationBtnType(activity) {
            const status = this.participationStatus[activity.id];
            return status === '已参与' ? 'danger' : 'primary';
        },

        // 获取参与按钮上的文本
        getParticipationBtnText(activity) {
            const status = this.participationStatus[activity.id];
            return status === '已参与' ? '退出活动' : '参与活动';
        },

        // 处理参与/退出活动操作
        async handleParticipation(activity) {
            const status = this.participationStatus[activity.id];
            const isQuit = status === '已参与';
            const actionText = isQuit ? '退出' : '参与';
            const confirmText = isQuit ? 
                '确认要退出该活动吗？' : 
                '确认要参与该活动吗？';

            try {
                await this.$confirm(confirmText, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });

                // 设置加载状态
                this.activityList = this.activityList.map(item => {
                    if (item.id === activity.id) {
                        return { ...item, loading: true };
                    }
                    return item;
                });

                const api = isQuit ? '/api/volunteer-activity/quit' : '/api/volunteer-activity/join';
                const res = await request.post(api, { activityId: activity.id,userId:this.userMsg.userId });

                if (res.data.code === 200) {
                    this.$message.success(res.data.message);
                    // 更新参与状态：进入审核中状态
                    this.participationStatus[activity.id] = isQuit ? '退出待审核' : '参与待审核';
                } else {
                    this.$message.error(res.data.message || `${actionText}失败`);
                }
            } catch (error) {
                if (error !== 'cancel') {
                    this.$message.error(`${actionText}失败`);
                }
            } finally {
                // 清除加载状态
                this.activityList = this.activityList.map(item => {
                    if (item.id === activity.id) {
                        return { ...item, loading: false };
                    }
                    return item;
                });
            }
        },

        showActivityDetail(activity) {
            this.currentActivity = activity;
            this.dialogVisible = true;
        },

        handleSearch() {
            this.pagination.currentPage = 1;
            this.getActivityList();
        },

        resetSearch() {
            this.searchForm = {
                name: '',
                category: '',
                location: ''
            };
            this.handleSearch();
        },

        handleSizeChange(val) {
            this.pagination.pageSize = val;
            this.getActivityList();
        },

        handleCurrentChange(val) {
            this.pagination.currentPage = val;
            this.getActivityList();
        }
    }
}
</script>

<style scoped>
.activity-participation {
    padding: 20px;
    background-color: #fff;
    min-height: 100%;
}

.search-wrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.activity-list {
    margin-bottom: 20px;
}

.activity-card {
    margin-bottom: 20px;
    transition: all 0.3s;
    border-radius: 15px;
}

.activity-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}

.activity-image {
    height: 200px;
    overflow: hidden;
}

.activity-image .el-image {
    width: 100%;
    height: 100%;
}

.activity-info {
    padding: 15px;
}

.activity-name {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.activity-category {
    margin-bottom: 10px;
}

.activity-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #606266;
    font-size: 14px;
}

.activity-time, .activity-participants {
    margin-bottom: 10px;
    color: #909399;
    font-size: 13px;
}

.activity-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.activity-actions .el-tag {
    margin-right: auto;  /* 让标签靠左对齐 */
}

.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
}

/* 详情弹窗样式 */
.detail-content {
    padding: 20px;
}

.detail-item {
    margin-bottom: 15px;
    line-height: 20px;
}

.detail-label {
    color: #606266;
    margin-right: 10px;
}

.detail-value {
    color: #303133;
}

.image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f5f7fa;
}

.teaching-content {
    .content-box {
        max-height: 200px;
        overflow-y: auto;
        padding: 10px;
        background-color: #f5f7fa;
        border-radius: 4px;
        line-height: 1.6;
        white-space: pre-wrap;
        word-break: break-all;
    }

    /* 自定义滚动条样式 */
    .content-box::-webkit-scrollbar {
        width: 6px;
    }

    .content-box::-webkit-scrollbar-thumb {
        background: #909399;
        border-radius: 3px;
    }

    .content-box::-webkit-scrollbar-track {
        background: #f5f7fa;
    }
}

</style>