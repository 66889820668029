import Mock from "mockjs";

//用户管理-列表接口
Mock.mock(/\/api\/user(\?.+)?$/, 'get', function(options) {
    // 解析 URL 参数
    const url = new URL(options.url, 'http://dummy.com');
    const page = parseInt(url.searchParams.get('page')) || 1;
    const pageSize = parseInt(url.searchParams.get('pageSize')) || 10;
    const name = url.searchParams.get('name') || '';
    const phone = url.searchParams.get('phone') || '';
    const membershipType = url.searchParams.get('membershipType') || '';

    // 预设一些学院和专业数据
    const colleges = ['经济学院', '管理学院', '信息学院', '外国语学院', '理学院'];
    const majors = {
        '经济学院': ['经济学', '金融学', '国际经济与贸易'],
        '管理学院': ['工商管理', '市场营销', '人力资源管理'],
        '信息学院': ['计算机科学与技术', '软件工程', '信息管理'],
        '外国语学院': ['英语', '日语', '法语'],
        '理学院': ['数学', '物理学', '化学']
    };
    const grades = ['2020级', '2021级', '2022级', '2023级'];

    // 生成100条模拟数据
    let list = Mock.mock({
        [`list|100`]: [{
            'id|+1': 1,
            'name': '@cname',
            'phone': /^1[3-9]\d{9}$/,
            'studentId': /^1[3-9]\d{5}$/,
            'status|1': ['启用', '账户冻结'],
            'registrationDate': '@date("yyyy-MM-dd")',
            'membershipExpiryDate': '@date("yyyy-MM-dd")',
            'AccountBalance|1-10000': 1,
            // 新增字段
            'college': function() {
                return Mock.Random.pick(colleges);
            },
            'major': function() {
                const college = this.college;
                return Mock.Random.pick(majors[college]);
            },
            'grade': function() {
                return Mock.Random.pick(grades);
            }
        }]
    }).list;

    // 搜索过滤
    if (name) {
        list = list.filter(item => item.name.includes(name));
    }
    if (phone) {
        list = list.filter(item => item.phone.includes(phone));
    }
    if (membershipType) {
        list = list.filter(item => item.membershipType === membershipType);
    }

    // 分页处理
    const total = list.length;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const pageList = list.slice(start, end);

    return {
        code: 200,
        data: {
            list: pageList,
            total: total
        }
    };
});

//用户管理-新增接口
Mock.mock("/api/user", "post", function(options) {
    const body = JSON.parse(options.body);
    
    // 生成模拟数据
    const newUser = {
        ...body,
        id: Mock.Random.id(),
        registrationDate: Mock.Random.date('yyyy-MM-dd'),
        membershipExpiryDate: Mock.Random.date('yyyy-MM-dd'),
        AccountBalance: Mock.Random.integer(0, 10000),
        // 新增默认值
        college: body.college || '信息学院',
        major: body.major || '软件工程',
        grade: body.grade || '2023级'
    };

    return {
        code: 200,
        message: "新增成功",
        data: newUser
    };
});

//用户管理-编辑接口
Mock.mock(/\/api\/user\/\d+/, "put", {
    code: 200,
    message: "编辑成功",
    data: null
});

//用户管理-删除接口
Mock.mock(/\/api\/user\/\d+/, "delete", {
    code: 200,
    message: "删除成功",
    data: null
});



// 图片上传接口
Mock.mock('/api/upload', 'post', {
    code: 200,
    message: '上传成功',
    data: {
        url:Mock.Random.image('750x500', '#3f51b5', '#FFF', '750x500'),
    }
});
// 用户管理-登录接口
Mock.mock('/api/login', 'post', function(options) {
    const body = JSON.parse(options.body);
    const { username, password } = body;

    // 简单的用户名和密码验证
    if (['admin','teacher','student'].includes(username) && password === 'password') {
        return {//role- admin-管理员 teacher-普通用户 student-普通用户
            code: 200,
            message: '登录成功',
            data: {
                token: 'fake-jwt-token', // 模拟的JWT令牌
                user: {
                    username: username,
                    role: username,
                    userId:'1213245645'
                }
            }
        };
    } else {
        return {
            code: 401,
            message: '用户名或密码错误',
            data: null
        };
    }
});
Mock.mock('/api/statistics', 'get', {
    volunteerCount: 150,        //志愿者数
    eventCount: 20,        // 模拟教练总数
    volunteerHours: 30000,       // 模拟课程总数
    monthlyIncome: 500   // 本月小时数
}); 
// 模拟饼图数据接口
Mock.mock('/api/pieData', 'get', [
    { value: 25, name: '计量经济学' },
    { value: 100, name: '高等数学' },
    { value: 10, name: '微观经济学' },
    { value: 122, name: '宏观经济学' }

]);

// 模拟折线图数据接口
Mock.mock('/api/lineData', 'get', [
    { date: '一月', newVolunteer: 20, activeVolunteer: 5 },
    { date: '二月', newVolunteer: 32, activeVolunteer: 22 },
    { date: '三月', newVolunteer: 90, activeVolunteer: 30 },
    { date: '四月', newVolunteer: 94, activeVolunteer: 40 },
    { date: '五月', newVolunteer: 100, activeVolunteer: 55 },
    { date: '六月', newVolunteer: 130, activeVolunteer: 80 },
    { date: '七月', newVolunteer: 150, activeVolunteer: 120 }
]);
// 模拟柱状图数据接口
Mock.mock('/api/barData', 'get', [
    { month: '1月', income: 20 },
    { month: '2月', income: 10 },
    { month: '3月', income: 40 },
    { month: '4月', income: 60 },
    { month: '5月', income: 70 },
    { month: '6月', income: 110 }
]);

// 志愿者活动管理相关接口
const volunteerActivities = [];

// 生成模拟数据
for (let i = 0; i < 100; i++) {
    // 模拟教师授课的录音转文字内容
    const teachingContent = `今天这节课我们主要讲解${Mock.Random.ctitle(3, 5)}这个知识点。${Mock.Random.cparagraph(4, 6)}

大家要特别注意这里，${Mock.Random.cparagraph(3, 4)}。这是很多同学容易混淆的地方，我再重复一遍，${Mock.Random.csentence(15, 20)}。

接下来我们看一个具体的例子，${Mock.Random.cparagraph(5, 8)}。这个例子告诉我们，${Mock.Random.csentence(20, 30)}。

有同学问到了一个很好的问题，${Mock.Random.csentence(10, 20)}？这个问题很有代表性，${Mock.Random.cparagraph(3, 5)}。

我们继续往下看，${Mock.Random.cparagraph(6, 8)}。这部分内容可能有点抽象，我们可以这样理解，${Mock.Random.cparagraph(4, 6)}。

最后总结一下今天的重点，${Mock.Random.cparagraph(3, 5)}。下节课我们将继续讲解${Mock.Random.ctitle(3, 5)}相关的内容。大家下课后要及时复习今天讲的内容，如果有不明白的地方可以随时提问。`;

    volunteerActivities.push({
        id: i + 1,
        name: Mock.Random.ctitle(5, 10),
        category: Mock.Random.pick(['软件工程', '社会科学', '人文历史']),
        image: Mock.Random.image('750x500', '#3f51b5', '#FFF', '750x500'),
        location: Mock.Random.pick(['软件工程学院', '社会科学学院', '人文历史学院']),
        duration: Mock.Random.integer(1, 10),
        teacher: Mock.Random.cname(),
        startTime: Mock.Random.date() + ' ' + Mock.Random.time(),
        endTime: Mock.Random.date() + ' ' + Mock.Random.time(),
        description: Mock.Random.cparagraph(1, 3),
        teachingContent: teachingContent,
        participants: Mock.Random.integer(0, 5000),
        status: i < 3 ? '待开始' : '已结束'
    });
}

// 获取志愿者活动列表
Mock.mock(/\/api\/volunteer-activity(\?.+)?$/, 'get', function(options) {
    const url = new URL(options.url, 'http://dummy.com');
    const page = parseInt(url.searchParams.get('page')) || 1;
    const pageSize = parseInt(url.searchParams.get('pageSize')) || 10;
    const name = url.searchParams.get('name') || '';
    const category = url.searchParams.get('category') || '';
    const location = url.searchParams.get('location') || '';
    const status = url.searchParams.get('status') || '';

    let list = volunteerActivities;

    // 搜索过滤
    if (name) {
        list = list.filter(item => item.name.includes(name));
    }
    if (category) {
        list = list.filter(item => item.category === category);
    }
    if (location) {
        list = list.filter(item => item.location.includes(location));
    }
    if (status) {
        list = list.filter(item => item.status === status);
    }

    // 分页处理
    const total = list.length;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const pageList = list.slice(start, end);

    return {
        code: 200,
        data: {
            list: pageList,
            total: total
        }
    };
});

// 新增志愿者活动
Mock.mock('/api/volunteer-activity', 'post', function(options) {
    const body = JSON.parse(options.body);
    const newActivity = {
        ...body,
        id: volunteerActivities.length + 1, // 新增活动ID
        participants: 0 // 初始已参与人数
    };
    volunteerActivities.push(newActivity);
    return {
        code: 200,
        message: '添加成功',
        data: newActivity
    };
});



// 删除志愿者活动
Mock.mock(/\/api\/volunteer-activity\/\d+/, 'delete', function(options) {
    const id = parseInt(options.url.split('/').pop());
    const index = volunteerActivities.findIndex(activity => activity.id === id);
    if (index !== -1) {
        volunteerActivities.splice(index, 1);
        return {
            code: 200,
            message: '删除成功',
            data: null
        };
    }
    return {
        code: 400,
        message: '删除失败'
    };
});

// 更新志愿者活动状态接口：严格匹配 /api/volunteer-activity/{id}/status
Mock.mock(/^\/api\/volunteer-activity\/\d+\/status$/, 'put', function(options) {
  // 解析请求体中的 { status } 字段
  const { status } = JSON.parse(options.body);
  // 使用严格正则匹配获取 id
  const idMatch = options.url.match(/^\/api\/volunteer-activity\/(\d+)\/status$/);
  if (!idMatch) {
    return {
      code: 400,
      message: 'URL格式错误'
    };
  }
  const id = parseInt(idMatch[1]);
  const activity = volunteerActivities.find(item => item.id === id);
  if (activity) {
    activity.status = status;
    return {
      code: 200,
      message: '状态更新成功'
    };
  }
  return {
    code: 400,
    message: '状态更新失败'
  };
});

// 编辑志愿者活动接口（仅匹配不带 /status 的情况）
// 注意：确保未注册重复的 PUT 接口，这里只保留一份
Mock.mock(/^\/api\/volunteer-activity\/\d+$/, 'put', function(options) {
  const updatedActivity = JSON.parse(options.body);
  const idMatch = options.url.match(/^\/api\/volunteer-activity\/(\d+)$/);
  if (!idMatch) {
    return {
      code: 400,
      message: 'URL格式错误'
    };
  }
  const id = parseInt(idMatch[1]);
  const index = volunteerActivities.findIndex(item => item.id === id);
  if (index !== -1) {
    volunteerActivities[index] = updatedActivity;
    return {
      code: 200,
      message: '编辑成功',
      data: null
    };
  }
  return {
    code: 400,
    message: '编辑失败'
  };
});

// 获取志愿活动参与状态
Mock.mock(/\/api\/volunteer-activity\/status(\?.+)?$/, 'get', function(options) {
    const url = new URL(options.url, 'http://dummy.com');
    const activityIds = url.searchParams.get('activityIds').split(',');
    
    // 固定部分状态，模拟展示不同状态效果
    const fixedStatuses = {
        '1': '参与待审核',
        '2': '已参与',
        '3': '退出待审核'
    };
    
    const statusMap = {};
    activityIds.forEach(id => {
        // 如果活动ID存在固定状态，则使用，否则随机返回状态
        statusMap[id] = fixedStatuses[id] || Mock.Random.pick([
            '未参与',
            '已参与',
            '参与待审核',
            '退出待审核'
        ], 1, false);
    });
    
    return {
        code: 200,
        data: statusMap
    };
});

// 参与活动接口
Mock.mock('/api/volunteer-activity/join', 'post', {
    code: 200,
    message: '参与申请已提交，请等待审核',
    data: null
});

// 退出活动接口
Mock.mock('/api/volunteer-activity/quit', 'post', {
    code: 200,
    message: '退出申请已提交，请等待审核',
    data: null
});

// 活动参与记录管理相关接口
Mock.mock(/\/api\/activity-orders(\?.+)?$/, 'get', function(options) {
    const url = new URL(options.url, 'http://dummy.com');
    const page = parseInt(url.searchParams.get('page')) || 1;
    const pageSize = parseInt(url.searchParams.get('pageSize')) || 10;
    const id = url.searchParams.get('id') || '';
    const userName = url.searchParams.get('userName') || '';
    const activityName = url.searchParams.get('activityName') || '';
    const status = url.searchParams.get('status') || '';

    let list = Mock.mock({
        [`list|100`]: [{
            'id': '@guid',
            'userName': '@cname',
            'activityName': '@ctitle(5,10)',
            'location': '@city',
            'status|1': ['参与待审核', '已参与', '退出待审核', '已退出'],
            'applyTime': '@datetime("yyyy-MM-dd HH:mm:ss")',
            'auditTime': '@datetime("yyyy-MM-dd HH:mm:ss")',
            'createTime': '@datetime("yyyy-MM-dd HH:mm:ss")'
        }]
    }).list;

    // 搜索过滤
    if (id) {
        list = list.filter(item => item.id.indexOf(id) !== -1);
    }
    if (userName) {
        list = list.filter(item => item.userName.indexOf(userName) !== -1);
    }
    if (activityName) {
        list = list.filter(item => item.activityName.indexOf(activityName) !== -1);
    }
    if (status) {
        list = list.filter(item => item.status === status);
    }

    // 分页处理
    const total = list.length;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const pageList = list.slice(start, end);

    return {
        code: 200,
        data: {
            list: pageList,
            total: total
        }
    };
});

// 审核活动参与记录接口
Mock.mock(/\/api\/activity-orders\/.*\/audit/, 'put', function(options) {
    const body = JSON.parse(options.body);
    return {
        code: 200,
        message: `活动${body.type === 'join' ? '参与' : '退出'}申请${body.action}成功`,
        data: null
    };
});

// 新接口：用户参与活动列表
Mock.mock(/\/api\/my-activities/, 'get', function(options) {
  const params = new URLSearchParams(options.url.split('?')[1]);
  const page = parseInt(params.get('page')) || 1;
  const pageSize = parseInt(params.get('pageSize')) || 10;
  
  const total = 57; // 数据总数，可根据需要调整
  const list = [];
  
  for (let i = 0; i < pageSize; i++) {
    const idx = (page - 1) * pageSize + i + 1;
    if (idx > total) break;
    list.push({
      // 随机生成活动名称、地点及时长
      activityName: Mock.Random.ctitle(5, 8),
      location: Mock.Random.city(),
      duration: `${Mock.Random.integer(1, 5)}小时`,
      status: Mock.Random.pick(['参与待审核', '已参与', '退出待审核', '已退出']),
      applyTime: Mock.Random.datetime(),
      createTime: Mock.Random.datetime()
    });
  }
  
  return {
    code: 200,
    data: {
      list,
      total
    },
    message: '成功'
  }
});

// 更新：用户接口返回的数据增加认证荣誉、累计活动时长及互动数
Mock.mock(/\/api\/userAct/, 'get', function(options) {
  const params = new URLSearchParams(options.url.split('?')[1]);
  const page = parseInt(params.get('page')) || 1;
  const pageSize = parseInt(params.get('pageSize')) || 20;
  const total = 57; // 模拟总数
  const list = [];
  
  for (let i = 0; i < pageSize; i++) {
    const idx = (page - 1) * pageSize + i + 1;
    if (idx > total) break;
    list.push({
      id: idx,
      name: Mock.Random.name(),
      phone: Mock.Random.string('number', 11),
      certificationHonors: [Mock.Random.ctitle(3, 5),Mock.Random.ctitle(3, 5)],
      registrationDate: Mock.Random.date(),
      cumulativeActivityDuration: Mock.Random.integer(50, 300) + "小时",
      interactionCount: Mock.Random.integer(10, 200),
      status: Mock.Random.pick(['启用', '账户冻结'])
    });
  }
  
  return {
    code: 200,
    data: {
      list,
      total
    },
    message: '成功'
  }
});

// 新增认证荣誉接口：更新用户认证荣誉
Mock.mock(/\/api\/user\/\d+\/honor/, 'put', function(options) {
  console.log('options',options)
    // 模拟更新成功
  return {
    code: 200,
    message: '认证荣誉更新成功'
  }
});

// 问题检索接口
Mock.mock('/api/question/ask', 'post', function(options) {
    const body = JSON.parse(options.body);
    const { question, userId } = body;
    console.log('body', question, userId )
    // 模拟 AI 回答
    const answers = [
        "根据课程内容，这个问题的答案是...",
        "这个概念可以这样理解...",
        "从教材的角度来看，这个问题涉及到...",
        "这个问题的关键点在于...",
        "让我为你详细解释一下这个问题..."
    ];
    
    return {
        code: 200,
        data: {
            answer: Mock.Random.pick(answers) + Mock.Random.cparagraph(2, 4),
            questionId: Mock.Random.guid(),
            createTime: Mock.Random.datetime()
        }
    };
});

// 问题历史记录接口
Mock.mock(/\/api\/question\/history(\?.+)?$/, 'get', function(options) {
    const url = new URL(options.url, 'http://dummy.com');
    const page = parseInt(url.searchParams.get('page')) || 1;
    const pageSize = parseInt(url.searchParams.get('pageSize')) || 20;
    const userId = url.searchParams.get('userId');
console.log(page,userId)
    // 生成模拟历史记录数据
    let list = Mock.mock({
        [`list|${pageSize}`]: [{
            'id|+1': 1,
            'question': '@csentence(10, 20)',
            'answer': '@cparagraph(2, 4)',
            'time': '@datetime("yyyy-MM-dd HH:mm:ss")'
        }]
    }).list;

    return {
        code: 200,
        data: {
            list,
            total: 100 // 模拟总记录数
        }
    };
});

// 语音识别接口
Mock.mock('/api/speech-to-text', 'post', function(options) {
    console.log('options',options)
    // 模拟语音识别的延迟
    const recognizedTexts = [
        "这个问题我想请教一下",
        "能帮我解释一下这个概念吗",
        "我对这个知识点有些疑问",
        "请问这道题该怎么解",
        "这个公式是什么意思",
        "能详细说明一下吗"
    ];
    
    return {
        code: 200,
        data: {
            text: Mock.Random.pick(recognizedTexts),
            duration: Mock.Random.float(1, 5, 1, 2) // 模拟音频时长（秒）
        },
        message: '语音识别成功'
    };
});

// 课堂分析统计数据
Mock.mock('/api/class/analysis/statistics', 'get', {
    code: 200,
    data: {
        totalClasses: Mock.Random.integer(100, 200),
        avgDuration: Mock.Random.integer(30, 50),
        totalQuestions: Mock.Random.integer(500, 1000),
        solveRate: Mock.Random.integer(85, 98)
    }
});

// 课堂时长分布数据
Mock.mock('/api/class/analysis/duration', 'get', {
    code: 200,
    data: [
        { value: Mock.Random.integer(20, 40), name: '30分钟以下' },
        { value: Mock.Random.integer(40, 60), name: '30-45分钟' },
        { value: Mock.Random.integer(10, 30), name: '45分钟以上' }
    ]
});

// 问题词云数据
Mock.mock('/api/class/analysis/word-cloud', 'get', {
    code: 200,
    data: [
        { name: '函数', value: Mock.Random.integer(60, 100) },
        { name: '微积分', value: Mock.Random.integer(50, 90) },
        { name: '导数', value: Mock.Random.integer(40, 80) },
        { name: '极限', value: Mock.Random.integer(30, 70) },
        { name: '积分', value: Mock.Random.integer(20, 60) },
        // ... 更多词云数据
    ]
});

// 问题趋势数据
Mock.mock('/api/class/analysis/question-trend', 'get', {
    code: 200,
    data: {
        dates: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        values: Array(7).fill(0).map(() => Mock.Random.integer(50, 150))
    }
});

// 问题分类数据
Mock.mock('/api/class/analysis/question-category', 'get', {
    code: 200,
    data: {
        categories: ['概念理解', '计算方法', '应用问题', '证明题', '其他'],
        values: Array(5).fill(0).map(() => Mock.Random.integer(50, 200))
    }
});

// 教师统计数据接口
Mock.mock(/\/api\/teacher\/statistics/, 'get', {
    code: 200,
    data: {
        courseCount: Mock.Random.integer(10, 30),
        studentCount: Mock.Random.integer(50, 200),
        totalHours: Mock.Random.integer(100, 500),
        interactionCount: Mock.Random.integer(200, 1000),
        // 其他教师相关的统计数据...
    }
});

// 学生统计数据接口
Mock.mock(/\/api\/student\/statistics/, 'get', {
    code: 200,
    data: {
        courseCount: Mock.Random.integer(5, 15),
        studyHours: Mock.Random.integer(20, 100),
        questionCount: Mock.Random.integer(10, 50),
        interactionRate: Mock.Random.integer(60, 95),
        // 其他学生相关的统计数据...
    }
});

// 学生管理相关接口
Mock.mock(/\/api\/student(\?.+)?$/, 'get', function(options) {
    // 解析 URL 参数
    const url = new URL(options.url, 'http://dummy.com');
    const page = parseInt(url.searchParams.get('page')) || 1;
    const pageSize = parseInt(url.searchParams.get('pageSize')) || 10;
    const name = url.searchParams.get('name') || '';
    const phone = url.searchParams.get('phone') || '';
    const studentId = url.searchParams.get('studentId') || '';

    // 预设一些学院和专业数据
    const colleges = ['经济学院', '管理学院', '信息学院', '外国语学院', '理学院'];
    const majors = {
        '经济学院': ['经济学', '金融学', '国际经济与贸易'],
        '管理学院': ['工商管理', '市场营销', '人力资源管理'],
        '信息学院': ['计算机科学与技术', '软件工程', '信息管理'],
        '外国语学院': ['英语', '日语', '法语'],
        '理学院': ['数学', '物理学', '化学']
    };
    const grades = ['2020级', '2021级', '2022级', '2023级'];

    // 生成100条模拟数据
    let list = Mock.mock({
        [`list|100`]: [{
            'id|+1': 1,
            'name': '@cname',
            'phone': /^1[3-9]\d{9}$/,
            'studentId': /^2[0-3]\d{8}$/,
            'college': function() {
                return Mock.Random.pick(colleges);
            },
            'major': function() {
                const college = this.college;
                return Mock.Random.pick(majors[college]);
            },
            'grade': function() {
                return Mock.Random.pick(grades);
            },
            'status|1': ['启用', '账户冻结'],
            'registrationDate': '@date("yyyy-MM-dd")'
        }]
    }).list;

    // 搜索过滤
    if (name) {
        list = list.filter(item => item.name.includes(name));
    }
    if (phone) {
        list = list.filter(item => item.phone.includes(phone));
    }
    if (studentId) {
        list = list.filter(item => item.studentId.includes(studentId));
    }

    // 分页处理
    const total = list.length;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const pageList = list.slice(start, end);

    return {
        code: 200,
        data: {
            list: pageList,
            total: total
        }
    };
});

// 新增学生接口
Mock.mock("/api/student", "post", function(options) {
    const body = JSON.parse(options.body);
    
    // 生成模拟数据
    const newStudent = {
        ...body,
        id: Mock.Random.id(),
        registrationDate: Mock.Random.date('yyyy-MM-dd')
    };

    return {
        code: '200',
        message: "新增成功",
        data: newStudent
    };
});

// 编辑学生接口
Mock.mock(/\/api\/student\/\d+/, "put", {
    code: '200',
    message: "编辑成功",
    data: null
});

// 删除学生接口
Mock.mock(/\/api\/student\/\d+/, "delete", {
    code: '200',
    message: "删除成功",
    data: null
});
