import axios from 'axios';

// 创建 axios 实例
const service = axios.create({
  // 基础地址，后续需要更换时只需修改这里即可
  baseURL: '/',//http://10.10.156.31:8080/
  timeout: 5000 // 请求超时时间
});

// 请求拦截器，可用于添加 token 等
service.interceptors.request.use(
  config => {
    // 例如：统一添加请求头
    config.headers['Content-Type'] = 'application/json';
    // 如果需要自定义的 token，也可以写在这里
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器，可用于处理全局响应错误
service.interceptors.response.use(
  response => response,
  error => {
    // 这里可以根据不同错误状态进行统一处理
    return Promise.reject(error);
  }
);

export default service; 