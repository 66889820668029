<template>
    <el-container style="height: 100vh">
        <el-header>
            <div class="image">
                <img src="../../assets/logo.png" alt="" style="width: 28px; height: 28px;">
            </div>
            <div style="margin-right: 20px;color: white; ">智能教学管理系统</div>

            <div class="user-right-box">
                <div class="recording-container" v-if="isRecording">
                    <i class="el-icon-video-camera recording-icon"></i>
                    <span class="course-name">{{ currentCourse.name }}</span>
                    <span class="recording-status">录制中</span>
                    <el-button 
                        type="text" 
                        class="stop-button"
                        @click="handleStopRecording">
                        <i class="el-icon-video-pause"></i>
                    </el-button>
                </div>
                <el-dropdown class="user-right">
                    <div style="color: white; font-size: 15px;"> {{userMsg.username}}</div>
                    <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
                    <!-- <el-dropdown-item>新增</el-dropdown-item>
                    <el-dropdown-item>删除</el-dropdown-item> -->
                </el-dropdown-menu>
            </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px" style="background-color: #545c64;">
                <el-menu ref="menu" default-active="0" background-color="#545c64" text-color="#fff"
                    active-text-color="#ffd04b" router @select="handleSelect">
                    <template v-for="(item, index) in routerArr">
                        <el-menu-item v-if="!item.group" :key="index" :index="String(index)" :route="item.route">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </el-menu-item>
                        <el-menu-item-group v-else :key="item.groupTitle">
                            <template slot="title">{{ item.groupTitle }}</template>
                            <el-menu-item v-for="(subItem) in item.children" 
                                :key="subItem.title"
                                :index="String(subItem.index)"
                                :route="subItem.route">
                                <i :class="subItem.icon"></i>
                                <span slot="title">{{ subItem.title }}</span>
                            </el-menu-item>
                        </el-menu-item-group>
                    </template>
                </el-menu>
            </el-aside>
            <el-main>
                <el-card shadow="never" class="top-head">
                    <!-- <div slot="header">
                        <span style="font-size: 20px;">{{ title[titIndex] }}</span>
                    </div> -->
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/HomePage' }"
                            @click.native="goHomePage()">主页</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="breadcrumb">{{ title[titIndex] }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-card>
                <div class='contnt-page'>
                    <router-view></router-view>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import axios from 'axios';
export default {

    data() {
        const item = {
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
        };
        return {
            classopen:false,
            tableData: Array(20).fill(item),
            title: ['主页','问题检索', '学习记录', '实时知识扩展', '课堂内容记录', '课堂分析', '学生管理', '教师管理','课堂内容记录管理','知识拓展记录管理','学生问题记录管理'],
            routerArr: [
                {
                    title: '主页',
                    icon: 'el-icon-s-home',
                    route: '/HomePage',
                    index: '0'
                },
                // {
                //     group: true,
                //     groupTitle: '学生端',
                //     children: [
                //         {
                //             title: '问题检索',
                //             icon: 'el-icon-document',
                //             route: '/ActTime',
                //             index: '1'
                //         },
                //         {
                //             title: '学习记录',
                //             icon: 'el-icon-s-data',
                //             route: '/ActivityList',
                //             index: '2'
                //         }
                //     ]
                // },
                // {
                //     group: true,
                //     groupTitle: '教师端',
                //     children: [
                //         {
                //             title: '实时知识扩展',
                //             icon: 'el-icon-s-comment',
                //             route: '/PersonHonor',
                //             index: '3'
                //         },
                //         {
                //             title: '课堂内容记录',
                //             icon: 'el-icon-folder-opened',
                //             route: '/classRecord',
                //             index: '4'
                //         },
                //         {
                //             title: '课堂分析',
                //             icon: 'el-icon-document-checked',
                //             route: '/classDeal',
                //             index: '5'
                //         }
                //     ]
                // },
                {
                    group: true,
                    groupTitle: '管理员端',
                    children: [
                        {
                            title: '学生管理',
                            icon: 'el-icon-s-custom',
                            route: '/studentManage',
                            index: '6'
                        },
                        {
                            title: '教师管理',
                            icon: 'el-icon-user-solid',
                            route: '/teacherManage',
                            index: '7'
                        },
                        {
                            title: '课堂内容记录管理',
                            icon: 'el-icon-s-order',
                            route: '/UsersManage',
                            index: '8'
                        },
                        {
                            title: '知识拓展记录管理',
                            icon: 'el-icon-menu',
                            route: '/ActiveManage',
                            index: '9'
                        },
                        {
                            title: '学生问题记录管理',
                            icon: 'el-icon-s-grid',
                            route: '/OrderManage',
                            index: '10'
                        },

                    ]
                },
            ],
            titIndex: 0,
            breadcrumb: false,
            userMsg:null,
            isRecording: false,
            currentCourse: {
                id: '',
                name: ''
            }
        }
    },
    methods: {
        axi() {
            axios.get('https://jsonplaceholder.typicode.com/posts')
                .then(response => console.log(response.data))
                .catch(error => console.error(error));
        },
        handleSelect(index) {
            // 菜单事件  
            if (index == 0) {
                this.goHomePage()
                return
            }
            this.titIndex = index
            this.breadcrumb = true
        },
        goHomePage() {
            // 面包屑，返回主页
            this.$refs.menu.activeIndex = '0'
            this.titIndex = 0
            this.breadcrumb = false
        },
        loginOut(){
            localStorage.clear('userInfo')
            this.$router.push('/LoginPage')
        },
        handleStopRecording() {
            this.$confirm('确认要停止录制当前课程吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 这里可以调用停止录制的接口
                this.isRecording = false;
                this.currentCourse = { id: '', name: '' };
                this.$message.success('已停止录制');
            }).catch(() => {});
        },
        updateRecordingStatus(status, courseInfo = null) {
            this.isRecording = status;
            if (courseInfo) {
                this.currentCourse = courseInfo;
            } else {
                this.currentCourse = { id: '', name: '' };
            }
            
            // 可以选择将状态保存到 localStorage
            if (status) {
                localStorage.setItem('recordingStatus', JSON.stringify({
                    isRecording: status,
                    courseInfo: this.currentCourse
                }));
            } else {
                localStorage.removeItem('recordingStatus');
            }
        }
    },
    mounted() {
        this.userMsg=JSON.parse(localStorage.getItem('userInfo'))
         if(this.userMsg.role=='teacher'){
            this.routerArr = [
                {
                    title: '主页',
                    icon: 'el-icon-s-home',
                    route: '/HomePage',
                    index: '0'
                },
                {
                    group: true,
                    groupTitle: '教师端',
                    children: [
                        {
                            title: '实时知识扩展',
                            icon: 'el-icon-s-comment',
                            route: '/PersonHonor',
                            index: '3'
                        },
                        {
                            title: '课堂内容记录',
                            icon: 'el-icon-folder-opened',
                            route: '/classRecord',
                            index: '4'
                        },
                        {
                            title: '课堂分析',
                            icon: 'el-icon-document-checked',
                            route: '/classDeal',
                            index: '5'
                        }
                    ]
                },

            ]
         }

         if(this.userMsg.role=='student'){
            this.routerArr = [
                {
                    title: '主页',
                    icon: 'el-icon-s-home',
                    route: '/HomePage',
                    index: '0'
                },
                {
                    group: true,
                    groupTitle: '学生端',
                    children: [
                        {
                            title: '文本问题检索',
                            icon: 'el-icon-document',
                            route: '/ActTime',
                            index: '2'
                        },
                        {
                            title: '学习记录查询',
                            icon: 'el-icon-s-data',
                            route: '/ActivityList',
                            index: '3'
                        }
                    ]
                },

            ]
         }
        // axios.get('https://jsonplaceholder.typicode.com/posts')
        //     .then(response => console.log(response.data))
        //     .catch(error => console.error(error));
        
        // 恢复录制状态
        const savedStatus = localStorage.getItem('recordingStatus');
        if (savedStatus) {
            const { isRecording, courseInfo } = JSON.parse(savedStatus);
            this.updateRecordingStatus(isRecording, courseInfo);
        }
    },
    watch: {
        $route: {
            handler(to) {
                if (to.query.recording === 'true') {
                    this.updateRecordingStatus(true, {
                        id: to.query.courseId,
                        name: to.query.courseName || '未知课程'
                    });
                } else if (to.query.recording === 'false') {
                    this.updateRecordingStatus(false);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.image {
    background: white;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;

    img {
        filter: brightness(0) saturate(100%) invert(32%) sepia(15%) saturate(2714%) hue-rotate(197deg) brightness(94%) contrast(93%);
    }
}

.el-header {
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
}

.el-aside {
    color: #333;
}
.contnt-page{
    height: calc(100% - 80px);
    border: 1px solid #EBEEF5;
    // padding: 0px 10px
}
.user-right{
    // position: absolute !important;
    // right: 40px;
}
.course-subscription{
    background-color: #fff;
}
// .el-main {
//     .el-card {}
// }
//菜单样式调整
.el-aside{
    background-color: #fff !important;
}
.el-menu{
    background-color: #fff !important;
}
.el-menu-item{
    background-color: #fff !important;
    color: #444 !important;
}
.el-menu-item.is-active{
    color: var(--primary-color) !important;
}
.el-header{
    width: calc(100% - 20px);
    margin-left: 10px;
    border-radius: 30px;
    margin-top: 10px;
    padding:0px 10px;
}
.el-button--primary:focus, .el-button--primary:hover{
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.el-button--primary{
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important; 
}
.cell .el-button--primary{
    background: transparent !important;
    color: var(--primary-color) !important;
    border: none;
}
.contnt-page{
    box-shadow: 0px 0px 10px var(--primary-color-light);
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff !important;
}
.course-subscription{
    background-color: #fff !important;
}
.top-head{
    margin-bottom: 10px;
    border-radius: 30px;
}
.top-head .el-card__body{
    padding: 15px;
}
.user-right-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute !important;
    right: 40px;
}
.recording{
margin-right: 10px;
}
.recording-container {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 12px;
    border-radius: 20px;
    margin-right: 20px;
    
    .recording-icon {
        color: #ff4949;
        font-size: 18px;
        margin-right: 8px;
        animation: blink 1.5s infinite;
    }
    
    .course-name {
        color: #fff;
        margin-right: 8px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .recording-status {
        color: #fff;
        font-size: 14px;
        margin-right: 8px;
    }
    
    .stop-button {
        color: #fff;
        padding: 0;
        margin-left: 8px;
        
        &:hover {
            color: #ff4949;
        }
        
        i {
            font-size: 18px;
        }
    }
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.4; }
    100% { opacity: 1; }
}
</style>
