<template>
  <div id="app">
    <router-view v-if="isLoginPage"></router-view>
    <ai-class-index v-else></ai-class-index>
  </div>
</template>

<script>
import aiClassIndex from './views/layout/aiClassIndex.vue';


export default {
  components: { aiClassIndex },
  computed: {
    isLoginPage() {
      return this.currentRoute === 'LoginPage'; // 检查当前路由是否为登录页面
    }
  },
  watch: {
    '$route'(to) {
      this.currentRoute = to.name; // 监听路由变化
    }
  },
  data() {
    return {
      currentRoute: this.$route.name // 初始化当前路由
    };
  }
}
</script>

<style>

</style>
