<!--  -->
<template>
    <div class='question-search'>
        <div class="chat-container">
            <div class="chat-messages" ref="chatMessages">
                <div v-for="(message, index) in messages" :key="index" 
                    :class="['message', message.type === 'user' ? 'user-message' : 'ai-message']">
                    <div class="message-content">
                        {{ message.content }}
                    </div>
                </div>
            </div>
            <div class="chat-input">
                <div class="input-wrapper">
                    <el-input
                        v-model="questionInput"
                        placeholder="请输入您的问题"
                        @focus="showHistory = true"
                        @keyup.enter.native="handleSendQuestion">
                    </el-input>
                    <!-- 历史搜索记录下拉框 -->
                    <div v-show="showHistory && searchHistory.length > 0" class="search-history">
                        <div v-for="(item, index) in searchHistory" 
                            :key="index" 
                            class="history-item"
                            @click="selectHistory(item)">
                            <i class="el-icon-time"></i>
                            <span>{{ item }}</span>
                            <i class="el-icon-delete" @click.stop="removeHistory(index)"></i>
                        </div>
                    </div>
                </div>
                <!-- 语音输入按钮 -->
                <el-button 
                    :type="isRecording ? 'danger' : 'warning'"
                    @mousedown="startRecording"
                    @mouseup="stopRecording"
                    @mouseleave="stopRecording">
                    <i :class="isRecording ? 'el-icon-microphone' : 'el-icon-mic'"></i>
                    {{ isRecording ? '松开结束' : '按住说话' }}
                </el-button>
                <!-- 发送按钮 -->
                <el-button type="primary" @click="handleSendQuestion" 
                    :disabled="!questionInput.trim()">发送</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import request from '@/utils/request';

export default {
    data() {
        return {
            activeTab: 'search',
            questionInput: '',
            messages: [
                {
                type:'ai',
                content:'你好，我是你的实时知识扩展助手，请问有什么可以帮你的吗？'                    
                }
            ],
            historyData: [],
            userMsg: null,
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            isRecording: false,
            mediaRecorder: null,
            audioChunks: [],
            showHistory: false,
            searchHistory: [], // 搜索历史记录
        };
    },
    mounted() {
        this.userMsg = JSON.parse(localStorage.getItem('userInfo'));
        this.getHistoryData();
        this.loadSearchHistory();
        // 点击其他地方时隐藏历史记录
        document.addEventListener('click', this.hideHistory);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideHistory);
    },
    methods: {
        // 开始录音
        async startRecording() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.mediaRecorder = new MediaRecorder(stream);
                this.audioChunks = [];
                
                this.mediaRecorder.ondataavailable = (event) => {
                    this.audioChunks.push(event.data);
                };
                
                this.mediaRecorder.onstop = async () => {
                    const audioBlob = new Blob(this.audioChunks);
                    // 发送音频到后端进行识别
                    const formData = new FormData();
                    formData.append('audio', audioBlob);
                    
                    try {
                        const response = await request.post('/api/speech-to-text', formData);
                        if (response.data.code === 200) {
                            this.questionInput = response.data.data.text;
                        }
                    } catch (error) {
                        this.$message.error('语音识别失败');
                    }
                };
                
                this.mediaRecorder.start();
                this.isRecording = true;
            } catch (error) {
                this.$message.error('无法访问麦克风');
            }
        },
        
        // 停止录音
        stopRecording() {
            if (this.mediaRecorder && this.isRecording) {
                this.mediaRecorder.stop();
                this.isRecording = false;
                this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
            }
        },
        
        // 加载搜索历史
        loadSearchHistory() {
            const history = localStorage.getItem('searchHistory');
            this.searchHistory = history ? JSON.parse(history) : [];
        },
        
        // 保存搜索历史
        saveSearchHistory(question) {
            if (!this.searchHistory.includes(question)) {
                this.searchHistory.unshift(question);
                if (this.searchHistory.length > 10) { // 最多保存10条
                    this.searchHistory.pop();
                }
                localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
            }
        },
        
        // 选择历史记录
        selectHistory(item) {
            this.questionInput = item;
            this.showHistory = false;
        },
        
        // 删除历史记录
        removeHistory(index) {
            this.searchHistory.splice(index, 1);
            localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
        },
        
        // 隐藏历史记录
        hideHistory(event) {
            if (!event.target.closest('.input-wrapper')) {
                this.showHistory = false;
            }
        },

        // 发送问题
        async handleSendQuestion() {
            if (!this.questionInput.trim()) return;
            
            // 保存到搜索历史
            this.saveSearchHistory(this.questionInput);
            
            // 添加用户消息
            this.messages.push({
                type: 'user',
                content: this.questionInput
            });

            try {
                const res = await request.post('/api/question/ask', {
                    question: this.questionInput,
                    userId: this.userMsg.userId
                });

                if (res.data.code === 200) {
                    // 添加 AI 回复
                    this.messages.push({
                        type: 'ai',
                        content: res.data.data.answer
                    });
                    
                    // 清空输入
                    this.questionInput = '';
                    
                    // 滚动到底部
                    this.$nextTick(() => {
                        const container = this.$refs.chatMessages;
                        container.scrollTop = container.scrollHeight;
                    });
                }
            } catch (error) {
                this.$message.error('发送失败');
            }
        },

        // 获取历史记录
        getHistoryData() {
            const params = {
                page: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                userId: this.userMsg.userId
            };
            
            request.get("/api/question/history", { params }).then((res) => {
                if (res.data.code === 200) {
                    this.historyData = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                }
            });
        },

        handleSizeChange(val) {
            this.pagination.pageSize = val;
            this.getHistoryData();
        },

        handleCurrentChange(val) {
            this.pagination.currentPage = val;
            this.getHistoryData();
        }
    }
}
</script>

<style scoped>
.question-search {
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.chat-container {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
}

.chat-messages {
    flex: 0.95;
    overflow-y: auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 20px;
    display: flex;
}

.user-message {
    justify-content: flex-end;
}

.ai-message {
    justify-content: flex-start;
}

.message-content {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 8px;
    word-break: break-word;
}

.user-message .message-content {
    background-color: var(--primary-color);
    color: white;
}

.ai-message .message-content {
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.chat-input {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.chat-input .el-button {
    /* height: 82px; */
}

.history-container {
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
}

.pagination-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

/* 滚动条样式 */
.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-track {
    background: #f5f5f5;
}

/* 新增样式 */
.input-wrapper {
    position: relative;
    flex: 1;
}

.search-history {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    box-shadow: 0 -2px 12px 0 rgba(0,0,0,.1);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
}

.search-history::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 20px;
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid #dcdfe6;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.history-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.history-item:hover {
    background-color: #f5f7fa;
}

.history-item i.el-icon-time {
    color: #909399;
}

.history-item i.el-icon-delete {
    margin-left: auto;
    color: #909399;
}

.history-item i.el-icon-delete:hover {
    color: #f56c6c;
}

.search-history::-webkit-scrollbar {
    width: 6px;
}

.search-history::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
}

.search-history::-webkit-scrollbar-track {
    background: #f5f5f5;
}
</style>