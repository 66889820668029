import Vue from 'vue'
import VueRouter from 'vue-router'
import ActivityList from '@/views/subscription/ActivityList.vue'
import PersonHonor from '@/views/authority/PersonHonor.vue'
import ActTime from '@/views/subscription/ActTime.vue'


// 用以解决访问同一路由报错的情况—————————————————————————
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// ————————————————————————————————————————————————————
Vue.use(VueRouter)

const routes = [
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('../views/management/HomePage.vue')
  },
  {
    path: '/LoginPage',
    name: 'LoginPage',
    component: () => import('../views/management/LoginPage.vue')
  },
  {
    path: '/ActivityList',
    name: 'ActivityList',
    component: ActivityList
  },
  {
    path: '/ActTime',
    name: 'ActTime',
    component: ActTime
  },
  {
    path: '/PersonHonor',
    name: 'PersonHonor',
    component: PersonHonor
  },
  {
    path: '/classRecord',
    name: 'classRecord',
    component: () => import('../views/authority/classRecord.vue')
  },
  {
    path: '/classDeal',
    name: 'classDeal',
    component: () => import('../views/authority/classDeal.vue')
  },
  {
    path: '/VipManage',
    name: 'VipManage',
    component: () => import('../views/management/VipManage.vue')
  },
  {
    path: '/UsersManage',
    name: 'UsersManage',
    component: () => import('../views/management/UsersManage.vue')
  },
  {
    path: '/teacherManage',
    name: 'teacherManage',
    component: () => import('../views/management/teacherManage.vue')
  },
  {
    path: '/studentManage',
    name: 'studentManage',
    component: () => import('../views/management/studentManage.vue')
  },
  // {
  //   path: '/CoachManage',
  //   name: 'CoachManage',
  //   component: () => import('../views/management/CoachManage.vue')
  // },
  {
    path: '/ActiveManage',
    name: 'ActiveManage',
    component: () => import('../views/management/ActiveManage.vue')
  },
  {
    path: '/OrderManage',
    name: 'OrderManage',
    component: () => import('../views/management/OrderManage.vue')
  },
  {
    path: '/UserActivityList',
    name: 'UserActivityList',
    component: () => import('../views/management/UserActivityList.vue')
  },
  {
    path: '/',
    redirect:'LoginPage'
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 如果目标路由是登录页，则直接放行，防止无限重定向
  if (to.path === '/LoginPage') {
    next();
    return;
  }
  const userInfo = localStorage.getItem('userInfo');
  if (!userInfo) {
    next('/LoginPage');
  } else {
    const isLoggedIn = JSON.parse(userInfo);
    if (!isLoggedIn || isLoggedIn === '') {
      next('/LoginPage');
    } else {
      next();
    }
  }
});

export default router
