<!--  -->
<template>
    <div class='question-search'>
        <el-tabs v-model="activeTab" type="card">
            <!-- AI 对话搜索界面 -->
            <el-tab-pane label="问题检索" name="search">
                <div class="chat-container">
                    <div class="chat-messages" ref="chatMessages">
                        <div v-for="(message, index) in messages" :key="index" 
                            :class="['message', message.type === 'user' ? 'user-message' : 'ai-message']">
                            <div class="message-content">
                                {{ message.content }}
                            </div>
                        </div>
                    </div>
                    <div class="chat-input">
                        <el-input
                            v-model="questionInput"
                            placeholder="请输入您的问题"
                            @keyup.enter.native="handleSendQuestion">
                        </el-input>
                        <el-button type="primary" @click="handleSendQuestion" 
                            :disabled="!questionInput.trim()">发送</el-button>
                    </div>
                </div>
            </el-tab-pane>

            <!-- 历史记录界面 -->
            <el-tab-pane label="历史记录" name="history">
                <div class="history-container">
                    <el-table 
                        :data="historyData"
                        style="width: 100%"
                        height="calc(100vh - 380px)"
                        >
                        <el-table-column 
                            prop="question"
                            label="问题"
                            min-width="200"
                            show-overflow-tooltip>
                        </el-table-column>
                        <!-- <el-table-column 
                            prop="answer"
                            label="答案"
                            min-width="300">
                        </el-table-column> -->
                        <el-table-column 
                            prop="time"
                            label="搜索时间"
                            width="180">
                        </el-table-column>
                    </el-table>
                    
                    <!-- 分页 -->
                    <div class="pagination-wrapper">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pagination.currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pagination.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pagination.total">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import request from '@/utils/request';

export default {
    data() {
        return {
            activeTab: 'search',
            questionInput: '',
            messages: [],
            historyData: [],
            userMsg: null,
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
        };
    },
    mounted() {
        this.userMsg = JSON.parse(localStorage.getItem('userInfo'));
        this.getHistoryData();
    },
    methods: {
        // 发送问题
        handleSendQuestion() {
            if (!this.questionInput.trim()) return;
            
            // 添加用户消息
            this.messages.push({
                type: 'user',
                content: this.questionInput
            });

            // 发送到后端
            request.post('/api/question/ask', {
                question: this.questionInput,
                userId: this.userMsg.userId
            }).then(res => {
                if (res.data.code === 200) {
                    // 添加 AI 回复
                    this.messages.push({
                        type: 'ai',
                        content: res.data.data.answer
                    });
                    
                    // 清空输入
                    this.questionInput = '';
                    
                    // 滚动到底部
                    this.$nextTick(() => {
                        const container = this.$refs.chatMessages;
                        container.scrollTop = container.scrollHeight;
                    });
                    
                    // 刷新历史记录
                    this.getHistoryData();
                }
            });
        },

        // 获取历史记录
        getHistoryData() {
            const params = {
                page: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                userId: this.userMsg.userId
            };
            
            request.get("/api/question/history", { params }).then((res) => {
                if (res.data.code === 200) {
                    this.historyData = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                }
            });
        },

        handleSizeChange(val) {
            this.pagination.pageSize = val;
            this.getHistoryData();
        },

        handleCurrentChange(val) {
            this.pagination.currentPage = val;
            this.getHistoryData();
        }
    }
}
</script>

<style scoped>
.question-search {
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.chat-container {
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
}

.chat-messages {
    flex: 0.95;
    overflow-y: auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 20px;
    display: flex;
}

.user-message {
    justify-content: flex-end;
}

.ai-message {
    justify-content: flex-start;
}

.message-content {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 8px;
    word-break: break-word;
}

.user-message .message-content {
    background-color: var(--primary-color);
    color: white;
}

.ai-message .message-content {
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.chat-input {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.chat-input .el-button {
    /* height: 82px; */
}

.history-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* 添加表格滚动条样式 */
.el-table {
    flex: 1;
}

/* 自定义表格滚动条样式 */
.el-table__body-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
}

.el-table__body-wrapper::-webkit-scrollbar-track {
    background: #f5f5f5;
}

.pagination-wrapper {
    margin-top: 20px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
}
</style>